<template>
  <div>
    <dx-data-grid
      v-if="true"
      id="otherProductOutboundHistory"
      ref="otherProductOutboundHistoryRef"
      :data-source="dataSource"
      height="55vh"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :hover-state-enabled="true"
      :row-alternation-enabled="false"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-sorting mode="single" />
      <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
      <template #filterToolbar />
      <dx-column data-field="batchName" caption="Batch Name" />
      <dx-column data-field="itemAction" caption="Action" alignment="center" cell-template="itemActionCell" width="120" />
        <template #itemActionCell="{ data }">
          <span class="d-block badge" :class="getItemActionFormated(data.value).variant">
            {{ getItemActionFormated(data.value).text }}
          </span>
        </template>
      <dx-column data-field="msku" caption="MSKU" />
      <dx-column data-field="fnsku" caption="FNSKU" />
      <dx-column data-field="shipTime" caption="Shipped At" :width="120" data-type="date" />
      <dx-column data-field="quantity" caption="Quantity" :width="120" />
      <dx-column data-field="deliveredQty" caption="Delivered" cell-template="deliveredQtyTemplate" />
        <template #deliveredQtyTemplate="{ data }">
          <div>
            <span :class="getItemsDeliveredStatus(data)" class="d-block badge">
              {{ data.value }}
            </span>
          </div>
        </template>
      <dx-paging :page-size="pagerOptions.pageSize" />
      <dx-pager
        :allowed-page-sizes="pagerOptions.allowedPageSizes"
        :show-info="pagerOptions.showInfo"
        :show-navigation-buttons="pagerOptions.showNavigationButtons"
        :show-page-size-selector="pagerOptions.showPageSizeSelector"
        :visible="pagerOptions.visible"
      />
    </dx-data-grid>
  </div>
</template>

<script>
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate } from '@core/utils/filter'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import { otherProductOutboundDataSource } from './otherProductOutboundHistoryStore'

export default {
  /* eslint-disable no-unused-vars */
  mixins: [GridBase],
  props: {
    productId: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      dataSource: otherProductOutboundDataSource,
    }
  },
  computed: {
  },
  watch: {
    productId: {
      handler() {
        this.load()
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.dataSource.searchValue(this.productId)
      this.dataSource.load()
    },
    getItemsDeliveredStatus(data) {
      if (data.data.deliveredQty === 0) return 'badge-danger'
      if (data.data.deliveredQty === data.data.quantity) return 'badge-success'
      if (data.data.deliveredQty < data.data.quantity) return 'badge-warning'
      return 'badge-primary'
    },
    getItemActionFormated(itemAction) {
      if (!itemAction) return { variant: 'badge-light-danger', text: 'Unknown' }
      // if (itemAction === 'FBA') return { variant: 'badge-light-success', text: 'FBA' }
      if (itemAction === 'FBM') return { variant: 'badge-light-primary', text: 'FBM' }
      if (itemAction === 'DROPSHIP') return { variant: 'badge-light-warning', text: 'Dropship' }
      if (itemAction === 'FORWARDING') return { variant: 'badge-light-warning', text: 'Forwarding' }
      return { variant: 'badge-light-secondary', text: itemAction }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          type: 'primary',
          elementAttr: {
            class: 'mx-half',
          },
          stylingMode: 'outlined',
          hint: 'Download Product Outbound History',
          onClick: () => {
            this.onDownloadItems()
          },
        },
        location: 'after',
      })
    },
    async onDownloadItems(e) {
      const pager = new Pager({})
      const max = 2000
      pager.setPageNumber(0, max)
      const pageableQuery = `${pager.toCreatePageable()}`
      const response = await outboundShippingService.getOtherItemsByProductId(this.productId, pageableQuery)
      const data = response.data.body.content
      if (data === undefined || data === null || data?.length <= 0) {
        Notify.warning('There are not any items to download.')
        return
      }
      this.onDownloadExportData(data)
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Product Outbound History')
      worksheet.columns = [
        { header: 'Batch Name', key: 'batchName', width: 14 },
        { header: 'Action', key: 'itemAction', width: 12 },
        { header: 'MSKU', key: 'msku', width: 12 },
        { header: 'FNSKU', key: 'fnsku', width: 12 },
        { header: 'Shipped', key: 'shipTime', width: 18 },
        { header: 'Quantity', key: 'quantity', width: 18 },
        { header: 'Delivered', key: 'deliveredQty', width: 18 },
      ]

      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.batchName,
          item.itemAction,
          item.msku,
          item.fnsku,
          formatDate(item.shiptime),
          item.quantity,
          item.deliveredQty,
        ])
      })

      workbook.xlsx.writeBuffer().then(buffer => {
        const fileName = Date.now()
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `OutboundHistory-${fileName}.xlsx`,
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
